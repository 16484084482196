import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeagoDirective } from './timeago.directive';
import { TimeagoClock, TimeagoDefaultClock } from './timeago.clock';
import { TimeagoDefaultFormatter, TimeagoFormatter } from './timeago.formatter';
import { TimeagoPipe } from './timeago.pipe';

export interface TimeagoModuleConfig {
  clock?: Provider;
  formatter?: Provider;
}

@NgModule({
    exports: [TimeagoDirective, TimeagoPipe],
    imports: [CommonModule, TimeagoPipe, TimeagoDirective]
})
export class TimeagoModule {
  /**
   * Use this method in your root module to provide the TimeagoModule
   */
  static forRoot(config: TimeagoModuleConfig = {}): ModuleWithProviders<any> {
    return {
      ngModule: TimeagoModule,
      providers: [
        config.clock || {
          provide: TimeagoClock,
          useClass: TimeagoDefaultClock
        },
        config.formatter || {
          provide: TimeagoFormatter,
          useClass: TimeagoDefaultFormatter
        }
      ]
    };
  }

  /**
   * Use this method in your other (non root) modules to import the directive/pipe
   */
  static forChild(config: TimeagoModuleConfig = {}): ModuleWithProviders<any> {
    return {
      ngModule: TimeagoModule,
      providers: [
        config.clock || {
          provide: TimeagoClock,
          useClass: TimeagoDefaultClock
        },
        config.formatter || {
          provide: TimeagoFormatter,
          useClass: TimeagoDefaultFormatter
        }
      ]
    };
  }
}
