import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TimeagoFormatter } from './timeago.formatter';
import { TimeagoClock } from './timeago.clock';
import { coerceBooleanProperty, dateParser, isDefined } from './utils';

@Pipe({
  name: 'timeago',
  pure: false // required to update the value when stateChanges emits
})
export class TimeagoPipe implements PipeTransform, OnDestroy {
  private clockSubscription: Subscription;

  private date: number;
  private value: string;
  private live = true;

  /**
   * Emits on:
   * - Input change
   * - Clock tick
   */
  stateChanges = new Subject<void>();

  constructor(
    cd: ChangeDetectorRef,
    formatter: TimeagoFormatter,
    private clock: TimeagoClock
  ) {
    this.stateChanges.subscribe(() => {
      this.value = formatter.format(this.date);
      cd.markForCheck();
    });
  }

  transform(date: any, live?: boolean) {
    const _date = dateParser(date).valueOf();
    let _live: boolean;

    _live = isDefined(live) ? coerceBooleanProperty(live) : this.live;

    if (this.date === _date && this.live === _live) {
      return this.value;
    }

    this.date = _date;
    this.live = _live;

    if (this.date) {
      if (this.clockSubscription) {
        this.clockSubscription.unsubscribe();
        this.clockSubscription = undefined;
      }
      this.clockSubscription = this.clock
        .tick(this.date)
        .pipe(filter(() => this.live, this))
        .subscribe(() => this.stateChanges.next());
      this.stateChanges.next();
    } else {
      throw new SyntaxError(
        `Wrong parameter in TimeagoPipe. Expected a valid date, received: ${date}`
      );
    }

    return this.value;
  }

  ngOnDestroy() {
    if (this.clockSubscription) {
      this.clockSubscription.unsubscribe();
      this.clockSubscription = undefined;
    }
    this.stateChanges.complete();
  }
}
